/**
 * This is just used to sanitize ref if it doesn't exist :)
 * @param ref - useRef
 */
export default function useSanitizer(ref?: any) {
  if (!ref) {
    return null;
  } else {
    return ref.current;
  }
}
