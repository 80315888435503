import { AnimatePresence } from 'framer-motion';
import { Route, Switch, useLocation } from 'react-router-dom';
import { RoutePattern } from './RoutePattern';
import { About, ContactUs, Home, JoinUs, Models, PageNotFound } from '../app/index';
import SingleModel from '../app/Models/SingleModel';
import { useModelService } from '../app/Hooks/useModelService';

export default function AppRoutes() {
  const location = useLocation();
  useModelService().getModels();

  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.pathname}>
        <Route exact path={RoutePattern.Home} component={Home} />
        <Route path={RoutePattern.JoinUs} component={JoinUs} />
        <Route path={RoutePattern.About} component={About} />
        <Route path={RoutePattern.ContactUs} component={ContactUs} />
        <Route exact path={RoutePattern.Models} component={Models} />
        <Route exact path={RoutePattern.SingleModel} component={SingleModel} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </AnimatePresence>
  );
}
