import { MotionProps } from 'framer-motion';
import { TransitionPowerOne } from './Transitions';

export const SlideLeft: MotionProps = {
  initial: {
    opacity: 0,
    marginLeft: 100,
  },
  animate: {
    opacity: 1,
    marginLeft: 0,
  },
  exit: {
    opacity: 0,
    marginLeft: 100,
  },
  transition: {
    ...TransitionPowerOne,
    delay: 0.4,
  },
};
