let width = window.innerWidth;

window.onresize = function () {
  width = window.innerWidth;
};

export function sm() {
  if (width <= 640) {
    return true;
  }

  return false;
}

export function md() {
  if (width <= 768) {
    return true;
  }

  return false;
}

export function lg() {
  if (width <= 1024) {
    return true;
  }

  return false;
}

//
export function xl() {
  if (width <= 1280) {
    return true;
  }

  return false;
}
