import { Container, Footer, Heading } from '../../components';
import SocialMedia from '../../components/SocialMedia/SocialMedia';
import ModelShowcase from '../../components/ModelShowcase/ModelShowcase';
import Treysongz1 from '../../assets/images/treysongz/treysongz-1.jpg';
import Treysongz2 from '../../assets/images/treysongz/treysongz-2.jpg';
import Treysongz3 from '../../assets/images/treysongz/treysongz-3.jpg';
import Treysongz1Hair from '../../assets/images/treysongz/treysongz-hair.png';
import MobileImage from '../../assets/images/treysongz/treysongz-mobile.png';
import { motion } from 'framer-motion';
import InstagramShowcase from '../../components/Instagram/InstagramShowcase';
import { slideUpV2 } from '../../components/FramerMotion/SlideUp';
import { FadeIn } from '../../components/FramerMotion/FadeIn';
import { useEffect, useRef } from 'react';
import useScrolly from '../../components/Hooks/useScrolly';
import { selectAllInstagramFeedSelector } from '../../features/instagram/instagram.selector';
import { useSelector } from 'react-redux';

const modelSize = {
  lgh: '6.7%',
  lgtop: '-5.9%',
  h: '6.5%',
  top: '-6.5%',
};

export default function About() {
  const instagramFeed = useSelector(selectAllInstagramFeedSelector);
  const paragraphRef = useRef(null);
  const { gsapSlideToDown } = useScrolly();

  useEffect(() => {
    gsapSlideToDown(paragraphRef, '90%');
  }, [gsapSlideToDown]);

  return (
    <>
      <Container py="10" px="0">
        {/* 1st part */}
        <Heading level={2}>about</Heading>
        <div className="mt-10">
          <ModelShowcase
            objectPos="object-top"
            showcase={{ ...modelSize }}
            imageUrls={[Treysongz1, Treysongz2, Treysongz3, Treysongz1Hair]}
            mobileImageUrl={MobileImage}
            socials={['!#', '!#', '!#', '!#']}
            modelName="treysongz"
            modelInfo="Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta, odio! Ex molestias
      reprehenderit enim! Dignissimos dolor porro facere nemo, voluptates expedita ipsam nam,
      cupiditate cumque natus nisi corrupti, velit quis? voluptates expedita ipsam nam,
      cupiditate cumque natus nisi corrupti, velit quis?"
            instagramId="13358278"
            showHeadImage={true}
          />
        </div>

        {/* 2nd part */}
        <motion.div className="pt-10" {...FadeIn}>
          <InstagramShowcase scrollStart="50%" instagramFeed={instagramFeed} />
          <div className="pt-12 lg:pt-0">
            <Heading scrollStart="80%" level={3}>
              angels and angles
            </Heading>
            <SocialMedia scrollStart="100%" data={['!#', '!#', '!#', '!#']} />
            <div ref={paragraphRef}>
              <motion.p className="text-center mt-10 px-0 py-5 lg:px-32" {...slideUpV2}>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsa dolores est quam
                pariatur, voluptas animi. Eaque, perspiciatis corrupti. Repellat laborum harum
                laboriosam porro inventore eius saepe quasi aliquam. Provident deleniti consectetur,
                eaque eveniet hic, soluta, earum quod officiis iste maiores deserunt sint eligendi
              </motion.p>
            </div>
          </div>
        </motion.div>
      </Container>
      <Footer />
    </>
  );
}
