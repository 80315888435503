import { motion } from 'framer-motion';
import { CSSProperties } from 'react';
import { FadeIn } from '../FramerMotion/FadeIn';

interface ContainerProps {
  children?: any;
  py?: string;
  pb?: string;
  lgPb?: string;
  px?: string;
  className?: string;
  style?: CSSProperties;
}

export default function Container({
  children,
  py,
  pb,
  lgPb,
  px,
  className,
  style,
}: ContainerProps) {
  return (
    <motion.div
      {...FadeIn}
      className={`py-${py} pb-${pb} lg:pb-${lgPb}  px-${
        px ? px : '10'
      } md:px-24 lg:px-32 xl:px-56 ${className && className}`}
      style={{ ...style }}
    >
      {children}
    </motion.div>
  );
}
