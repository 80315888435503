import { motion, useCycle } from 'framer-motion';
import { useRef, useState } from 'react';
import { MenuToggle } from './MenuToggle';
import { useDimensions } from './useDimensions';
import { Menu, MenuTypes } from '../Menu';
import { Link, useHistory } from 'react-router-dom';
import {
  mobileMenuVariant,
  mobileMenuContainerVariant,
  mobileMenuItemVariant,
} from './FramerMotion';
import { useDispatch } from 'react-redux';
import { addCurrentRoute } from '../../../features/swiper/swiper.slice';
import Store from '../../../Store';
import { ReverseRoute } from '../../../helpers/CurrentRoute';

export default function MobileMenu() {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const dispatch = useDispatch();
  const swiper = Store.getState().swiper;

  const history = useHistory();
  const [menu, toggleMenu] = useState<string>(history.location.pathname);

  function handleMenuItemClick(i: MenuTypes) {
    toggleMenu(i.url);
    toggleOpen();
    dispatch(addCurrentRoute(i.url));
  }

  return (
    <motion.nav
      className="mobile-menu block lg:hidden z-20"
      initial={false}
      custom={height}
      animate={isOpen ? 'open' : 'closed'}
      ref={containerRef}
    >
      <motion.div
        className="w-full fixed z-50 bg-white h-0 -top-0 right-0"
        variants={mobileMenuContainerVariant}
      >
        <motion.ul
          className="flex flex-col justify-center h-full items-center uppercase"
          variants={mobileMenuVariant}
        >
          {Menu.map((i: MenuTypes, idx: number) => {
            return (
              <motion.li
                className={`${i.logo ? 'hidden' : 'block'} ${
                  ReverseRoute(swiper) === i.url ? 'current-menu' : ''
                }`}
                variants={mobileMenuItemVariant}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                key={idx}
              >
                <Link
                  className="mobile-menu-item  text-black font-normal opacity-70 hover:text-primary transform text-xl | sm:text-3xl md:text-4xl"
                  onClick={() => handleMenuItemClick(i)}
                  to={i.url}
                >
                  {i.name}
                </Link>
              </motion.li>
            );
          })}
        </motion.ul>
      </motion.div>

      <div className="bg-white shadow-md rounded-full h-14 w-14 flex justify-center items-center right-5 bottom-5 fixed z-50">
        <MenuToggle toggle={() => toggleOpen()} />
      </div>
    </motion.nav>
  );
}
