import { useEffect, useMemo } from 'react';
import InstagramService from '../../api/Instagram/InstagramService';

export default function useInstagramService() {
  useEffect(() => {
    let cleanup: ((id: string) => void)[] = [];

    const { getInstagramFeed } = new InstagramService();

    cleanup = [getInstagramFeed];

    return () => {
      cleanup.forEach((fn) => fn(''));
    };
  }, []);

  return useMemo(() => new InstagramService(), []);
}
