import { MotionProps } from 'framer-motion';
import { TransitionPowerOne } from './Transitions';

export const ShadowHeading: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 0.2,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    ...TransitionPowerOne,
    delay: 0.4,
  },
};

export const ShadowHeadingLinesRight: MotionProps = {
  initial: {
    opacity: 0,
    marginLeft: 30,
  },
  animate: {
    opacity: 1,
    marginLeft: 0,
  },
  exit: {
    opacity: 0,
    marginLeft: 30,
  },
  transition: {
    ...TransitionPowerOne,
    delay: 0.6,
  },
};

export const ShadowHeadingLinesLeft: MotionProps = {
  initial: {
    opacity: 0,
    marginRight: 30,
  },
  animate: {
    opacity: 1,
    marginRight: 0,
  },
  exit: {
    opacity: 0,
    marginRight: 30,
  },
  transition: {
    ...TransitionPowerOne,
    delay: 0.6,
  },
};
