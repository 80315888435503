import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import useNewsLetterService from '../../app/Hooks/useNewsletterService';
import Submitting from '../Submitting/Submitting';

export default function Subscribe() {
  const { getUsersIp, subscribeUser } = useNewsLetterService();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      if (formik.dirty && values.email.length) {
        subscribeUser({
          Email: values.email,
          IP: await getUsersIp(),
        });

        formik.resetForm();
        Swal.fire({
          title: 'Success',
          text: 'Your email has been submitted!',
          confirmButtonColor: '#000',
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Oops... Something went wrong',
          confirmButtonColor: '#d40016',
        });
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <input
        className="w-full lg:w-auto rounded-full mr-3 bg-gray-300 px-5 py-2 text-sm"
        type="text"
        name="email"
        placeholder="SUBSCRIBE"
        value={formik.values.email}
        onChange={formik.handleChange}
      />
      <button
        type="submit"
        className="w-full relative overflow-hidden mt-2 lg:mt-0 lg:w-auto rounded-full bg-black text-white tracking-widest uppercase px-8 py-2 text-sm hover:bg-primary hover:scale-110 transform transition-all"
      >
        submit
        <Submitting isSubmitting={formik.isSubmitting} />
      </button>
    </form>
  );
}
