import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { RoutePattern } from '../../routes/RoutePattern';
import useScrolly from '../Hooks/useScrolly';
import { findChexy } from './helpers/findChexy';
import InstagramSlideshow from './InstagramSlideshow';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
// import Socials from './Socials';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { ModelsTypes } from '../../api/Model/types';

interface InstagramModelProps {
  data: ModelsTypes[];
}

export default function InstagramModel({ data }: InstagramModelProps) {
  const modelItem: any = useRef(null);
  // const [currentItemHovered, setCurrentItemHovered] = useState<string>('');
  const { gsapBatchReveal } = useScrolly();

  useEffect(() => {
    findChexy(modelItem, data.length); // adds model-item
    const cleanup = setTimeout(() => {
      gsapBatchReveal(modelItem);
    });
    return () => {
      clearTimeout(cleanup);
      ScrollTrigger.getAll().forEach((instance) => {
        instance.kill();
      });
      // This in case a scroll animation is active while the route is updated
      gsap.killTweensOf(window);
    };
  }, [data.length, modelItem, gsapBatchReveal]);

  const mapData = data.map((item) => {
    return (
      <div
        className="model-item relative overflow-hidden h-full transform translate-y-16 mb-0 text-center inline-block opacity-0  | lg:mb-10 "
        key={item.id}
        // onMouseOver={() => setCurrentItemHovered(item.id)}
        // onMouseOut={() => setCurrentItemHovered(item.id)}
      >
        <Link className="mt-3 block" to={`${RoutePattern.Models}/${item.slug}`}>
          <InstagramSlideshow website={item.socials[0]} images={item.imgs} />
          <div className="text-center mt-10">
            <h3 className="uppercase italic">{item.name}</h3>
          </div>
        </Link>
        <a href={item.socials[1]} target="_black" rel="noreferrer">
          <FontAwesomeIcon
            className="text-3xl mt-3 mr-5 hover:text-white"
            icon={faInstagram}
            style={{
              filter: 'drop-shadow(0px 5px 5px rgba(0,0,0,.4))',
            }}
          />
        </a>
        <a href={item.socials[0]} target="_black" rel="noreferrer">
          <FontAwesomeIcon
            className="text-3xl mt-3 hover:text-white"
            icon={faHome}
            style={{
              filter: 'drop-shadow(0px 5px 5px rgba(0,0,0,.4))',
            }}
          />
        </a>
      </div>
    );
  });

  return (
    <div className="relative">
      <div ref={modelItem} className="model items-center gap-10 mt-10 min-h-screen">
        {mapData}
      </div>
    </div>
  );
}
