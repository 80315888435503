import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import ImageOverlay from '../../assets/images/insta/overlay.png';

interface DefaultPlayerProps {
  mp4: string;
  webm: string;
  poster: string;
}

export default function DefaultPlayer({ mp4, webm, poster }: DefaultPlayerProps) {
  const videoRef: any = useRef(null);
  const [hovered, setHovered] = useState(false);
  const [iconHovered, setIconHover] = useState(false);
  const [state, setState] = useState({
    isPlaying: false,
  });

  function handlePlayOrPause() {
    const isPlaying = !state.isPlaying;
    setState({ ...state, isPlaying });

    if (isPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }

  return (
    <div
      className="w-full relative overflow-hidden"
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <video
        ref={videoRef}
        className="w-full"
        preload="auto"
        controls={hovered && state.isPlaying}
        poster={poster}
      >
        <source src={mp4} type="video/mp4"></source>
        <source src={webm} type="video/webm"></source>
        {/* <source src="//vjs.zencdn.net/v/oceans.ogv" type="video/ogg"></source> */}
        <p className="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to a web browser that
          <a href="https://videojs.com/html5-video-support/" rel="noreferrer" target="_blank">
            supports HTML5 video
          </a>
        </p>
      </video>
      <div
        className={`position-center text-white text-3xl cursor-pointer z-20 transition-all ${
          iconHovered ? 'opacity-50' : 'opacity-100'
        } ${state.isPlaying ? 'hidden' : 'block'}`}
        onMouseOver={() => setIconHover(true)}
        onMouseOut={() => setIconHover(false)}
      >
        <button onClick={handlePlayOrPause}>
          <FontAwesomeIcon icon={faPlayCircle} />
        </button>
      </div>

      <img
        className={`w-full h-full absolute left-0 ${
          iconHovered || state.isPlaying ? 'slideDown' : 'top-0'
        }`}
        src={ImageOverlay}
        alt="black blur"
      />
    </div>
  );
}
