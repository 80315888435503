import { Carousel } from 'react-responsive-carousel';

interface SliderProps {
  children: any;
}

export default function Slider({ children }: SliderProps) {
  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      showArrows={false}
      showIndicators={true}
      infiniteLoop
      className="flex lg:hidden flex-row pt-10"
    >
      {children}
    </Carousel>
  );
}
