import Video1 from '../../assets/videos/IMG_0004.mp4';
import Video2 from '../../assets/videos/IMG_0005.mp4';
import Video3 from '../../assets/videos/IMG_0006.mp4';
import Video4 from '../../assets/videos/IMG_0007.mp4';
import Video5 from '../../assets/videos/IMG_0008.mp4';
import { motion } from 'framer-motion';
import { ScaleDown } from '../../components/FramerMotion/Scale';
import Player from '../../components/VideoPlayer/Player';
import Footer2 from '../../components/Footer/Footer2';
import React, { useEffect, useState } from 'react';
import Initialize from '../../init';
import { Preloader } from '../../components';

export default function Home() {
  const myVids = [Video1, Video2, Video3, Video4, Video5];
  const [loader, setLoader] = useState(true);
  const [loaded, setOnload] = useState(false);

  useEffect(() => {
    Initialize();

    window.onload = () => {
      setOnload(true);
    };

    setTimeout(() => {
      setLoader(false);
    }, 4800);
  }, []);

  return (
    <>
      {loader && !loaded ? (
        <Preloader />
      ) : (
        <div>
          <motion.div id="home" className="overflow-hidden h-full w-full" {...ScaleDown}>
            <Player url={myVids} />
          </motion.div>
          <Footer2 />
        </div>
      )}
    </>
  );
}
