import { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import { Preloader } from '..';

interface PlayerProps {
  url: string[];
}

export default function Player({ url }: PlayerProps) {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const slider: any = useRef(null);
  const videoPlayer: any = useRef(null);

  const mapUrl = url.map((i, idx) => {
    return (
      <div className="w-full h-full" key={idx}>
        <ReactPlayer
          fallback={<Preloader />}
          className="object-cover video-player"
          muted
          playsinline
          autoPlay
          playing={idx === currentIndex}
          onEnded={() => handleEnd(idx)}
          ref={videoPlayer}
          url={i}
          width="100vw"
          height="100vh"
        />
      </div>
    );
  });

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 800,
    autoPlay: false,
    autoplaySpeed: 2000,
    focusOnSelect: true,
    pauseOnHover: false,
    fade: true,
    dots: false,
  };

  function handleEnd(idx: number) {
    if (idx !== url.length - 1) {
      setCurrentIndex(idx + 1);
      slider!.current.slickNext();
    } else {
      setCurrentIndex(0);
      slider!.current.slickNext();
    }
  }

  return (
    <Slider ref={slider} {...settings}>
      {mapUrl}
    </Slider>
  );
}
