import { useSelector } from 'react-redux';
import { Container, Fairy, Footer, Heading } from '../../components';
import { InstagramModel } from '../../components/Instagram';
import { selectAllModelsSelector } from '../../features/model/model.selector';

export default function Models() {
  const models = useSelector(selectAllModelsSelector);

  if (!models) return null;

  return (
    <>
      <div className="relative overflow-hidden">
        <Container py="10" px="2">
          <Heading level={2}>models</Heading>
          <InstagramModel data={models} />
        </Container>
        <Fairy />
      </div>
      <Footer />
    </>
  );
}
