import { Footer, Heading } from '../../components';

export default function PageNotFound() {
  return (
    <>
      <div className="flex flex-col justify-center items-center" style={{ minHeight: '80vh' }}>
        <Heading level={2}>404</Heading>
        <Heading level={3}>page not found</Heading>
      </div>
      <Footer />
    </>
  );
}
