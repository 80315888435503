import Slider, { Settings } from 'react-slick';

export default function Slider2({ children }: any) {
  const settings: Settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 1000,
    autoplaySpeed: 2000,
    focusOnSelect: true,
    pauseOnHover: false,
    dots: false,
    autoplay: false,
    arrows: true,
    swipe: true,
    swipeToSlide: true,
    className: 'youtube-slider instagram-slider default-player relative w-full',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          swipe: true,
          swipeToSlide: true,
        },
      },
    ],
  };

  return <Slider {...settings}>{children}</Slider>;
}
