import axios from 'axios';
import { addModelItems } from '../../features/model/model.slice';
import { sluggify } from '../../helpers/Sluggify';
import Store from '../../Store';
import { ModelsTypes } from './types';

export default class ModelService {
  async getModels() {
    const { data } = await axios.get(
      'https://spreadsheets.google.com/feeds/cells/1zIxgQiL75uNL57P1-eOuF43TXheY340mMe-E5RAA23c/1/public/full?alt=json',
    );

    // constants
    const entries: ModelsTypes[] = [];
    const ids = [];
    const image1 = [];
    const image2 = [];
    const image3 = [];
    const name = [];
    const website = [];
    const instagram = [];
    const snapchat = [];
    const twitter = [];
    const facebook = [];
    const mobile_img = [];
    const model_info = [];
    const instagram_id = [];

    for (const entry of data.feed.entry) {
      const row = entry.gs$cell.row;
      const col = entry.gs$cell.col;
      const item = entry.content.$t;

      if (col === '1' && row !== '1') {
        ids.push(item);
      }
      if (col === '2' && row !== '1') {
        image1.push(item);
      }
      if (col === '3' && row !== '1') {
        image2.push(item);
      }
      if (col === '4' && row !== '1') {
        image3.push(item);
      }

      if (col === '5' && row !== '1') {
        name.push(item);
      }
      if (col === '6' && row !== '1') {
        instagram.push(item);
      }
      if (col === '7' && row !== '1') {
        website.push(item);
      }
      if (col === '8' && row !== '1') {
        snapchat.push(item);
      }
      if (col === '9' && row !== '1') {
        twitter.push(item);
      }

      if (col === '10' && row !== '1') {
        facebook.push(item);
      }
      if (col === '11' && row !== '1') {
        mobile_img.push(item);
      }

      if (col === '12' && row !== '1') {
        model_info.push(item);
      }

      if (col === '13' && row !== '1') {
        instagram_id.push(item);
      }
    }

    // arrange json data
    for (let i = 0; i < ids.length; i++) {
      entries.push({
        id: ids[i],
        imgs: [image1[i], image2[i], image3[i]],
        name: name[i],
        socials: [website[i], instagram[i], snapchat[i], twitter[i], facebook[i]],
        mobile_img: mobile_img[i],
        model_info: model_info[i],
        slug: sluggify(name[i]),
        instagram_id: instagram_id[i],
      });
    }

    if (entries) {
      return Store.dispatch(addModelItems(entries));
    }
  }
}
