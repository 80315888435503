import { useEffect, useMemo } from 'react';
import ModelService from '../../api/Model/ModelService';

export function useModelService() {
  useEffect(() => {
    let cleanup: (() => void)[] = [];
    const { getModels } = new ModelService();

    cleanup = [getModels];

    return () => {
      cleanup.forEach((fn) => fn());
    };
  }, []);

  return useMemo(() => new ModelService(), []);
}
