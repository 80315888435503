import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InstagramStats from './InstagramStats';
import ImageOverlay from '../../assets/images/insta/overlay.png';
import { useState } from 'react';
import { InstagramTypes } from '../../api/Instagram/types';
import styled from 'styled-components';

const StyledDiv = styled.div`
  height: auto;
  @media (min-width: 1024px) {
    height: 350px;
  }
`;

export interface InstagramFeedProps extends InstagramTypes {}

export default function InstagramFeed({
  heartCount,
  commentCount,
  redirectTo,
  imgURL,
}: InstagramFeedProps) {
  const [backgroundHover, setBackgroundHover] = useState(false);

  return (
    <StyledDiv className={`w-full relative overflow-hidden`}>
      <a
        className={`position-center text-white text-3xl cursor-pointer z-20 transition-all ${
          backgroundHover ? 'opacity-0' : 'opacity-100'
        }`}
        href={redirectTo}
      >
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <img className="w-full h-full object-cover object-center z-0" src={imgURL} alt="lady 1" />
      <img
        className={`w-full h-full absolute left-0 ${backgroundHover ? 'slideDown' : 'top-0'}`}
        src={ImageOverlay}
        alt="black blur"
      />
      <div
        onMouseOver={() => setBackgroundHover(true)}
        onMouseOut={() => setBackgroundHover(false)}
        className="w-full h-full top-0 left-0 absolute opacity-0 hover:opacity-100 z-10 transition-all"
      >
        <InstagramStats heartCount={heartCount} commentCount={commentCount} />
      </div>
    </StyledDiv>
  );
}
