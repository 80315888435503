import axios from 'axios';
import { addVideosItems, removeAllVideos } from '../../features/videos/videos.slice';
import Store from '../../Store';
import { ModelsVideosTypes } from './types';

export default class VideosService {
  async getModelVideos(id: string) {
    const { data } = await axios.get(
      'https://spreadsheets.google.com/feeds/cells/1zIxgQiL75uNL57P1-eOuF43TXheY340mMe-E5RAA23c/2/public/full?alt=json',
    );

    if (!id) {
      return Store.dispatch(removeAllVideos());
    }

    // constants
    const entries: ModelsVideosTypes[] = [];
    const ids = [];

    const poster1 = [];
    const MP4_1 = [];
    const WEBM_1 = [];

    const poster2 = [];
    const MP4_2 = [];
    const WEBM_2 = [];

    for (const entry of data.feed.entry) {
      const row = entry.gs$cell.row;
      const col = entry.gs$cell.col;
      const item = entry.content.$t;

      if (col === '1' && row !== '1') {
        ids.push(item);
      }
      if (col === '2' && row !== '1') {
        poster1.push(item);
      }
      if (col === '3' && row !== '1') {
        MP4_1.push(item);
      }
      if (col === '4' && row !== '1') {
        WEBM_1.push(item);
      }
      if (col === '5' && row !== '1') {
        poster2.push(item);
      }
      if (col === '6' && row !== '1') {
        MP4_2.push(item);
      }
      if (col === '7' && row !== '1') {
        WEBM_2.push(item);
      }
    }

    // arrange json data
    for (let i = 0; i < ids.length; i++) {
      entries.push({
        id: ids[i],
        poster: [poster1[i], poster2[i]],
        mp4: [MP4_1[i], [MP4_2[i]]],
        webm: [WEBM_1[i], [WEBM_2[i]]],
      });
    }

    if (entries) {
      return Store.dispatch(addVideosItems(entries.filter((item) => item.id === id)[0]));
    }
  }
}
