import gsap from 'gsap';

gsap.config({
  nullTargetWarn: false,
});
export default function instagramFeedAnimation(scrollStart?: string, opacity?: number) {
  const targets = document.querySelectorAll('.instagram-feed');

  gsap.fromTo(
    targets,
    {
      y: -50,
      opacity: opacity ? opacity : 0.2,
    },
    {
      y: 0,
      scale: 1,
      opacity: 1,
      stagger: 0.1,
      scrollTrigger: {
        trigger: targets[0],
        start: `top ${scrollStart ? scrollStart : '85%'}`,
        scrub: 3,
        toggleActions: 'play restart resume reset',
      },
    },
  );
}
