import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../Store';
import { modelsAdapter } from './model.slice';

export const modelsSelector = modelsAdapter.getSelectors((state: RootState) => state.models.items);

// select all models
export const selectAllModelsSelector = createSelector(modelsSelector.selectAll, (item) => item);

// select entity by slug
export const selectBySlugSelector = (slug: string) =>
  createSelector(modelsSelector.selectAll, (item) => item.filter((i) => i.slug === slug)[0]);
