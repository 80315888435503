import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { motion } from 'framer-motion';
import SlideDown from '../Hooks/Scrolly/SlideDown';
import { useEffect, useRef, useState } from 'react';
// import Socials from './Socials';

interface InstagramSlideshowProps {
  images: string[];
  website: string;
}

export default function InstagramSlideshow({ images, website }: InstagramSlideshowProps) {
  const [whenHovered, hovered] = useState<boolean>(false);
  const slideRef: any = useRef(null);

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 300,
    autoPlay: false,
    autoplaySpeed: 2000,
    focusOnSelect: true,
    pauseOnHover: false,
    fade: true,
    dots: false,
  };

  useEffect(() => {
    if (whenHovered) {
      setTimeout(() => {
        slideRef!.current.slickPlay();
      });
    } else {
      slideRef!.current.slickPause();
    }
  }, [whenHovered]);

  const mapImages = images.map((image, idx) => {
    return (
      <motion.div
        key={idx}
        onMouseOver={() => hovered(true)}
        onMouseOut={() => hovered(false)}
        {...SlideDown}
      >
        <img className="model-img w-full object-cover" src={image} alt="models" />
      </motion.div>
    );
  });

  return (
    <div className="w-full relative overflow-hidden">
      <Slider ref={slideRef} {...settings}>
        {mapImages}
      </Slider>
      {/* <div onMouseOver={() => hovered(true)} onMouseOut={() => hovered(false)}>
        <Socials isActive={whenHovered} socials={{ website }} />
      </div> */}
    </div>
  );
}
