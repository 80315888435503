import gsap from 'gsap';

export default function SlideDownStagger(ref?: any, scrollStart?: string, opacity?: number) {
  const target: HTMLDivElement = ref.current;

  gsap.fromTo(
    target.children,
    {
      y: -50,
      opacity: opacity ? opacity : 0.2,
    },
    {
      y: 0,
      scale: 1,
      opacity: 1,
      stagger: 0.1,
      scrollTrigger: {
        trigger: target.children[0],
        start: `top ${scrollStart ? scrollStart : '85%'}`,
        scrub: 3,
        toggleActions: 'play restart resume reset',
      },
    },
  );
}
