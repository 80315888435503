export default function FadeUp(ref?: any) {
  const target: any = ref;

  if (!target) return;
  const socials = target.current.children;
  let delay = 0;

  for (let i = 0; i <= socials.length; i++) {
    if (!socials[i]) return;

    delay = delay + 300;

    let item = socials[i];

    item.setAttribute('data-aos', 'fade-up');
    item.setAttribute('data-once', 'false');
    item.setAttribute('data-aos-delay', delay);
  }
}
