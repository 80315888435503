import { useState } from 'react';

export default function InstagramIcon() {
  const [hovered, hover] = useState(false);

  return (
    <span className="twit-icon" onMouseOver={() => hover(true)} onMouseLeave={() => hover(false)}>
      <svg width="54" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M40.505 16.882c.022.302.022.604.022.906 0 9.212-7.012 19.826-19.826 19.826A19.691 19.691 0 0110 34.486c.56.065 1.1.086 1.683.086 3.257 0 6.256-1.1 8.65-2.977a6.98 6.98 0 01-6.514-4.832c.431.064.862.108 1.316.108.625 0 1.25-.087 1.833-.238a6.97 6.97 0 01-5.587-6.839v-.086a7.019 7.019 0 003.15.885 6.963 6.963 0 01-3.107-5.804c0-1.294.345-2.48.949-3.516a19.808 19.808 0 0014.368 7.292 7.862 7.862 0 01-.172-1.597A6.965 6.965 0 0133.537 10c2.006 0 3.818.841 5.091 2.2a13.719 13.719 0 004.423-1.682 6.95 6.95 0 01-3.064 3.84A13.97 13.97 0 0044 13.279a14.98 14.98 0 01-3.495 3.603z"
            fill={`${hovered ? 'black' : 'white'}`}
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0"
            width="54"
            height="47.614"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset />
            <feGaussianBlur stdDeviation="5" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
        </defs>
      </svg>
    </span>
  );
}
