import { useState } from 'react';

export default function InstagramIcon() {
  const [hovered, hover] = useState(false);

  return (
    <span className="insta-icon" onMouseOver={() => hover(true)} onMouseLeave={() => hover(false)}>
      <svg width="51" height="51" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M25.928 18.133a7.679 7.679 0 00-7.69 7.692 7.679 7.679 0 007.69 7.692 7.679 7.679 0 007.69-7.692 7.679 7.679 0 00-7.69-7.692zm0 12.693c-2.75 0-5-2.243-5-5.001 0-2.758 2.243-5 5-5 2.758 0 5 2.242 5 5s-2.249 5-5 5zm9.798-13.007a1.79 1.79 0 01-1.793 1.794 1.794 1.794 0 111.794-1.794zm5.094 1.82c-.114-2.403-.663-4.531-2.423-6.285-1.754-1.754-3.882-2.303-6.285-2.424-2.476-.14-9.898-.14-12.374 0-2.396.114-4.525.663-6.285 2.417-1.76 1.754-2.302 3.883-2.423 6.286-.14 2.477-.14 9.9 0 12.377.114 2.404.663 4.532 2.423 6.286 1.76 1.754 3.882 2.303 6.285 2.424 2.476.14 9.898.14 12.374 0 2.403-.114 4.531-.663 6.285-2.424 1.753-1.754 2.302-3.882 2.423-6.286.14-2.476.14-9.894 0-12.37zm-3.2 15.029a5.062 5.062 0 01-2.85 2.852c-1.975.783-6.66.602-8.842.602-2.181 0-6.873.174-8.84-.602a5.062 5.062 0 01-2.852-2.852c-.783-1.975-.602-6.66-.602-8.843 0-2.182-.174-6.875.602-8.843a5.062 5.062 0 012.851-2.852c1.975-.783 6.66-.602 8.841-.602 2.182 0 6.874-.174 8.841.602a5.062 5.062 0 012.852 2.852c.782 1.975.602 6.66.602 8.843 0 2.182.18 6.875-.602 8.843z"
            fill={`${hovered ? 'black' : 'white'}`}
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x=".925"
            y=".825"
            width="50"
            height="50"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset />
            <feGaussianBlur stdDeviation="5" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.69 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
        </defs>
      </svg>
    </span>
  );
}
