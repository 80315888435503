import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrentRoute } from '../../helpers/CurrentRoute';
import { RoutePattern } from '../../routes/RoutePattern';

export const swiperSlice = createSlice({
  name: 'models',
  initialState: CurrentRoute(RoutePattern.Home) as string,
  reducers: {
    addCurrentRoute: (state, { payload }: PayloadAction<string>) => (state = CurrentRoute(payload)),
    addLocation: (state, { payload }: PayloadAction<string>) => (state = payload),
  },
});

export const { addCurrentRoute, addLocation } = swiperSlice.actions;
