import { motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import {
  ShadowHeading,
  ShadowHeadingLinesLeft,
  ShadowHeadingLinesRight,
} from '../FramerMotion/Headings';
import { slideUpV2 } from '../FramerMotion/SlideUp';
import useScrolly from '../Hooks/useScrolly';
import useParallax from '../ParallaxEffect/useParallax';

interface HeadingProps {
  level: number;
  text?: string;
  children?: string;
  mt?: string;
  scrollStart?: string;
  opacity?: number;
}
export default function Heading({ level, text, children, mt, scrollStart, opacity }: HeadingProps) {
  let headingRef = useRef(null);
  let headingDepthsRef = useRef(null);

  const heading3Ref = useRef(null);
  const { gsapSlideToDown } = useScrolly();

  useParallax(headingRef);

  useEffect(() => {
    gsapSlideToDown(heading3Ref, scrollStart, opacity);
  }, [gsapSlideToDown, scrollStart, opacity]);

  switch (level) {
    case 2:
      return (
        <div
          className={`gsap-slide-right | text-center relative flex justify-center items-center mt-${mt}`}
          ref={headingRef}
        >
          <div
            className="text-center relative flex justify-center items-center bg-white overflow-visible z-10 w-full"
            data-depth="0.4"
          >
            <div className="flex flex-row items-center justify-center w-full">
              <motion.div
                className="bg-black rounded-full z-0"
                style={{ width: '50px', height: '4px' }}
                {...ShadowHeadingLinesLeft}
              ></motion.div>
              <motion.h2
                className="italic text-4xl uppercase z-10 mx-5"
                style={{
                  textShadow: 'rgba(0, 0, 0, 0.8) -7px 12px 15px',
                  left: '34%',
                  top: '22%',
                }}
                {...slideUpV2}
              >
                {children}
              </motion.h2>
              <motion.div
                className="bg-black rounded-full z-0"
                style={{ width: '50px', height: '4px' }}
                {...ShadowHeadingLinesRight}
              ></motion.div>
            </div>
            <div className="w-full" ref={headingDepthsRef}>
              <motion.h2
                className="w-full italic uppercase z-0 opacity-20 space-x-2 pt-7 text-6xl absolute -top-4 left-1/2 transform -translate-x-1/2"
                {...ShadowHeading}
                style={{ lineHeight: '3rem' }}
              >
                {children}
              </motion.h2>
            </div>
          </div>
        </div>
      );
    case 3:
      return (
        <div className={`text-center mt-${mt}`}>
          <h3 ref={heading3Ref} className="uppercase italic ">
            {children}
          </h3>
        </div>
      );
    default:
      return <p>{text}</p>;
  }
}
