import { useEffect } from 'react';
import { ModelsVideosTypes } from '../../api/Videos/types';
import useScrolly from '../../components/Hooks/useScrolly';
import DefaultPlayer from '../../components/VideoPlayer/DefaultPlayer';
import DefaultPlayerSlider from '../../components/VideoPlayer/DefaultPlayerSlider';
import useVideoService from '../Hooks/useVideoService';

interface PlaylistProps {
  id: string;
  videos: ModelsVideosTypes;
}

export default function Playlist({ videos, id }: PlaylistProps) {
  const { gsapVideoAnimation } = useScrolly();
  const { getModelVideos } = useVideoService();

  useEffect(() => {
    getModelVideos(id);
    gsapVideoAnimation();
  }, [id, gsapVideoAnimation, getModelVideos]);

  if (!videos) return null;

  return (
    <div className="relative">
      <DefaultPlayerSlider id={id}>
        {videos.mp4.map((item, idx) => (
          <div key={idx} className="playlist-video w-full">
            <DefaultPlayer poster={videos.poster[idx]} webm={videos.webm[idx]} mp4={item} />
          </div>
        ))}
      </DefaultPlayerSlider>
    </div>
  );
}
