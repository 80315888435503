import { instagramSlice } from './instagram/instagram.slice';
import { modelsSlice } from './model/model.slice';
import { swiperSlice } from './swiper/swiper.slice';
import { videosSlice } from './videos/videos.slice';

export const rootReducer = {
  models: modelsSlice.reducer,
  instagram: instagramSlice.reducer,
  videos: videosSlice.reducer,
  swiper: swiperSlice.reducer,
};
