import { MotionProps } from 'framer-motion';
import { TransitionPowerOne } from './Transitions';

export const SlideRight: MotionProps = {
  initial: {
    opacity: 0,
    marginRight: 250,
  },
  animate: {
    opacity: 1,
    marginRight: 0,
  },
  exit: {
    opacity: 0,
    marginRight: 250,
  },
  transition: {
    ...TransitionPowerOne,
    delay: 1.5,
  },
};
