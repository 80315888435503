import { useEffect, useMemo } from 'react';
import VideosService from '../../api/Videos/VideosService';

export default function useVideoService() {
  useEffect(() => {
    let cleanup: ((id: string) => void)[] = [];
    const { getModelVideos } = new VideosService();

    cleanup = [getModelVideos];

    return () => {
      cleanup.forEach((fn) => fn(''));
    };
  }, []);

  return useMemo(() => new VideosService(), []);
}
