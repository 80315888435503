import Parallax from 'parallax-js';
import { useEffect } from 'react';

export default function useParallax(ref: any) {
  const scene = ref;

  useEffect(() => {
    if (!scene.current) return;
    new Parallax(scene.current);
  }, [scene]);
}
