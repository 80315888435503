import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, PanInfo, useMotionValue, useTransform } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MobileLogo from '../../assets/images/logo-mobile.png';
import { swiperSelector } from '../../features/swiper/swiper.selector';
import { addLocation } from '../../features/swiper/swiper.slice';
import { CurrentRoute } from '../../helpers/CurrentRoute';
import { SlashRemoval } from '../../helpers/SlashRemoval';
import Store from '../../Store';
import { SlideDown } from '../FramerMotion/SlideDown';
import { SlideRight } from '../FramerMotion/SlideRight';
import MobileMenu from './Mobile/MobileMenu';

export default function SmallScreen() {
  const history = useHistory();
  const [location, setLocation] = useState<string>('');
  const containerRef: any = useRef(null);
  const [draggedWidth, setDraggedWidth] = useState<number>(0);
  const [reset, setReset] = useState(false);
  const x = useMotionValue(0);
  const xInput = [-100, 0, 100];
  const background = useTransform(x, xInput, [
    'linear-gradient(0deg, rgba(244,244,244,1) 54%, rgba(255,255,255,1) 100%)',
    'linear-gradient(0deg, rgba(221,221,221,1) 54%, rgba(210,210,210,1) 100%)',
    'linear-gradient(0deg, rgba(250,250,250,1) 54%, rgba(236,236,236,1) 100%)',
  ]);
  const dispatch = useDispatch();
  const [swiper, setSwiper] = useState('');

  useEffect(() => {
    setSwiper(Store.getState().swiper);

    if (draggedWidth === 100 && reset) {
      history.push(location);

      setTimeout(() => {
        setReset(false);
        setDraggedWidth(0);
      }, 5000);
    }

    if (draggedWidth === 0 && !reset) {
      handleDrag(null, undefined);
    }
  }, [history, location, reset, draggedWidth, swiper]);

  function handleDrag(_: any, info: PanInfo | any) {
    const width = containerRef.current.offsetWidth;
    let percentage = 0;

    const nextLocation = CurrentRoute(history.location.pathname);
    setLocation(nextLocation);

    dispatch(addLocation(nextLocation));

    if (!info) return;

    percentage = (info.point.x / width) * 100;

    if (percentage >= 100) {
      setDraggedWidth(100);
      setReset(true);
    }
  }

  console.log(swiper);

  return (
    <div className="lg:hidden block py-5 z-50">
      <div className="relative">
        <motion.img
          className="z-20 absolute -top-3"
          style={{ x, width: '55px' }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          onDrag={!reset ? handleDrag : undefined}
          dragElastic={1}
          dragMomentum={false}
          src={MobileLogo}
          alt="angels and angles mobile logo"
        />
        <motion.div
          ref={containerRef}
          className="relative flex justify-between items-center w-full bg-gray-700 rounded-full h-10 px-5 overflow-hidden"
          {...SlideDown}
          style={{ background }}
        >
          <p className="text-center m-auto uppercase tracking-wider text-sm font-bold opacity-20">
            swipe to {swiper === '/' || swiper === '/' ? 'home' : SlashRemoval(swiper)}
          </p>
          <motion.div className="absolute" {...SlideRight} style={{ right: '4%' }}>
            <FontAwesomeIcon className="text-2xl text-gray-500" icon={faAngleDoubleRight} />
          </motion.div>
          <div
            className="absolute left-0 top-0 z-0 h-10 rounded-full bg-gray-100 opacity-80"
            style={{ width: `${draggedWidth}%` }}
          />
        </motion.div>
      </div>
      <MobileMenu />
    </div>
  );
}
