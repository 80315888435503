import axios from 'axios';
import Store from '../../Store';
import { InstagramTypes } from './types';
import {
  addInstagramItems,
  removeAllInstagramItems,
} from '../../features/instagram/instagram.slice';

export default class InstagramService {
  async getInstagramFeed(id: string) {
    const { data } = await axios.get(
      `https://www.instagram.com/graphql/query/?query_hash=472f257a40c653c64c666ce877d59d2b&variables=%7B%22id%22:%22${id}%22,%22first%22:%2212%22%7D`,
    );

    const user = data.data.user;
    const edges = user?.edge_owner_to_timeline_media?.edges;

    if (!edges) {
      // once unmount, remove all items
      return Store.dispatch(removeAllInstagramItems());
    }

    const igFeed: InstagramTypes[] = [];

    for (let edge of edges) {
      const item = edge.node;

      igFeed.push({
        id: item.id,
        imgURL: item.display_url,
        redirectTo: `https://www.instagram.com/p/${item.shortcode}/`,
        heartCount: item.edge_media_preview_like.count,
        commentCount: item.edge_media_to_comment.count,
      });
    }

    if (igFeed.length >= 1) {
      // dispatch the ig feeds
      Store.dispatch(addInstagramItems([igFeed[0], igFeed[1], igFeed[2], igFeed[3]]));
    }
  }
}
