import gsap from 'gsap';
import useSanitizer from '../useSanitizer';

export default function SlideLeft(ref?: any, scrollStart?: string) {
  const target = useSanitizer(ref);

  gsap.fromTo(
    target,
    {
      x: 0,
      opacity: 1,
    },
    {
      x: 50,
      opacity: 0.5,
      scrollTrigger: {
        trigger: target,
        start: `top ${scrollStart ? scrollStart : '30%'}`,
        toggleActions: 'play restart resume reset',
        scrub: 1,
        pin: false,
        markers: false,
      },
    },
  );
}
