import { useState } from 'react';

export default function InstagramIcon() {
  const [hovered, hover] = useState(false);

  return (
    <span className="fb-icon" onMouseOver={() => hover(true)} onMouseLeave={() => hover(false)}>
      <svg width="35" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M24.017 25.754l.778-5.069H19.93v-3.289c0-1.387.68-2.738 2.858-2.738H25v-4.316S22.994 10 21.075 10c-4.005 0-6.623 2.428-6.623 6.822v3.863H10v5.069h4.452v12.253h5.48V25.754h4.085z"
            fill={`${hovered ? 'black' : 'white'}`}
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0"
            width="35"
            height="48.007"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset />
            <feGaussianBlur stdDeviation="5" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
        </defs>
      </svg>
    </span>
  );
}
