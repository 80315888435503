import { Container, Footer, Heading } from '../../components';
import ModelShowcase from '../../components/ModelShowcase/ModelShowcase';
import { useEffect, useRef } from 'react';
import useScrolly from '../../components/Hooks/useScrolly';
import { useSelector } from 'react-redux';
import { selectBySlugSelector } from '../../features/model/model.selector';
import { ModelsTypes } from '../../api/Model/types';
import InstagramShowcase from '../../components/Instagram/InstagramShowcase';
import { selectAllInstagramFeedSelector } from '../../features/instagram/instagram.selector';
import { selectAllVideosSelector } from '../../features/videos/videos.selector';
import Playlist from './Playlist';

interface SingleModelProps {
  match: { params: { slug: string } };
}

export default function SingleModel({ match }: SingleModelProps) {
  const slug = match.params.slug;
  const model: ModelsTypes = useSelector(selectBySlugSelector(slug));
  const instagramFeed = useSelector(selectAllInstagramFeedSelector);
  const videos = useSelector(selectAllVideosSelector);

  const video1 = useRef(null);
  const video2 = useRef(null);
  const paragraphRef = useRef(null);
  const { gsapSlideToDown } = useScrolly();

  useEffect(() => {
    gsapSlideToDown(paragraphRef);
    gsapSlideToDown(video1);
    gsapSlideToDown(video2);
  }, [gsapSlideToDown, model]);

  if (!model) return null;

  return (
    <>
      <Container py="11" px="0">
        <div className="relative">
          <Heading level={2}>{model.name}</Heading>
          <div className="mt-5 lg:mt-16">
            <ModelShowcase
              objectPos="object-center"
              imageUrls={model.imgs}
              mobileImageUrl={model.mobile_img}
              socials={model.socials}
              modelName={model.name}
              modelInfo={model.model_info}
              instagramId={model.instagram_id}
            />
          </div>
        </div>
        <div className="pt-16 pb-10">
          <InstagramShowcase scrollStart="50%" instagramFeed={instagramFeed} />
          <div>
            <div className="mt-24 lg:mt-0">
              <Heading scrollStart="60%" level={3}>
                instagram
              </Heading>
            </div>
          </div>
        </div>
        <Playlist id={model.id} videos={videos} />
        <div className="mt-10">
          <Heading scrollStart="90%" level={3}>
            videos
          </Heading>
        </div>
      </Container>
      <Footer />
    </>
  );
}
