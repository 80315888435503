import { motion } from 'framer-motion';
import IntroVideoMp4 from './videos/intro.mp4';
import IntroVideoWebm from './videos/intro.webm';
import IntroGif from './videos/intro.gif';
import { Link } from 'react-router-dom';
import { RoutePattern } from '../../routes/RoutePattern';

export default function Preloader() {
  return (
    <div className="fixed w-full h-full z-30 flex bg-none">
      <Link to={RoutePattern.Home}>
        <motion.video
          className="min-h-screen min-w-screen w-full h-full object-cover hidden md:block"
          autoPlay
          muted
          loop
          playsInline
          id="myVideo"
        >
          <source src={IntroVideoWebm} type="video/webm" />
          <source src={IntroVideoMp4} type="video/mp4" />
        </motion.video>

        <img
          className="w-full h-full object-cover block md:hidden"
          src={IntroGif}
          alt="Angels and Angles logo"
        />
      </Link>
    </div>
  );
}
