import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, Variants } from 'framer-motion';
import { useState } from 'react';
import styled from 'styled-components';
import Smoke from '../../assets/images/smoke.png';
import { sm } from '../../helpers/Screensize';
import { TransitionPowerOne } from '../FramerMotion/Transitions';

const FairyVariants: Variants = {
  initial: {
    height: sm() ? '5%' : '15%',
    transition: {
      ...TransitionPowerOne,
    },
  },
  animate: {
    height: '0',
  },
};

const IconVariants: Variants = {
  initial: {
    y: -30,
  },
  animate: {
    y: 50,
    transition: {
      ...TransitionPowerOne,
    },
  },
};

function MotionFairyWrapper(props: any) {
  return <motion.div {...props}>{props.children}</motion.div>;
}

const FairyWrapper = styled(MotionFairyWrapper)`
  bottom: -50px;
`;

export default function Fairy() {
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <FairyWrapper
        variants={FairyVariants}
        initial="initial"
        animate={isActive ? 'animate' : 'initial'}
        className="fairy absolute w-screen z-0 left-0 behind-1"
      >
        <div className="blur absolute"></div>
        <img className="h-full object-fill w-full z-0" src={Smoke} alt="smoke" />
        <div className="absolute top-0 flex flex-row  gap-20 justify-center w-full h-full items-center z-20">
          <div className=" mt-32 h-1 bg-black rounded-full w-full sm:w-20"></div>
          <button
            className="mt-32 text-3xl text-black whitespace-nowrap font-extrabold italic uppercase tracking-wider"
            onClick={() => setIsActive(true)}
          >
            View more
          </button>
          <div className="mt-32 h-1 bg-black rounded-full w-full sm:w-20"></div>
        </div>
      </FairyWrapper>
      <motion.button
        variants={IconVariants}
        animate={!isActive ? 'animate' : 'initial'}
        className="w-10 h-10 leading-0 m-auto flex items-center justify-center rounded-full text-xl border border-black bg-white"
        onClick={() => setIsActive(false)}
      >
        <FontAwesomeIcon icon={faAngleDoubleUp} />
      </motion.button>
    </>
  );
}
