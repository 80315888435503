import { useFormik } from 'formik';
import { motion } from 'framer-motion';
import useContactService from '../../app/Hooks/useContactService';
import { SlideLeft } from '../FramerMotion/SlideLeft';
import { slideUpV1 } from '../FramerMotion/SlideUp';
import Submitting from '../Submitting/Submitting';
import Swal from 'sweetalert2';

const input = 'bg-gray-200 px-3 py-2 rounded-xl text-sm text-gray-800 tracking-wide mb-5';

interface JoinProps {
  mw?: string;
  pt?: string;
}

export default function Join({ mw, pt }: JoinProps) {
  const { subscribeUser, getUsersIp } = useContactService();
  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      subject: '',
      body: '',
    },
    onSubmit: async (values) => {
      if (
        formik.dirty &&
        values.name.length &&
        values.email.length &&
        values.subject.length &&
        values.body.length
      ) {
        subscribeUser({
          Name: values.name,
          Email: values.email,
          Subject: values.subject,
          Message: values.body,
          IP: await getUsersIp(),
        });

        formik.resetForm();
        Swal.fire({
          title: 'Success',
          text: 'Your email has been submitted!',
          confirmButtonColor: '#000',
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Oops... Something went wrong',
          confirmButtonColor: '#d40016',
        });
      }
    },
  });

  return (
    <motion.form
      className="flex flex-col m-auto w-5/6 mt-10"
      onSubmit={formik.handleSubmit}
      style={{ maxWidth: `${mw}px`, paddingTop: `${pt}px` }}
      {...slideUpV1}
    >
      <input
        className={input}
        type="text"
        name="name"
        onChange={formik.handleChange}
        value={formik.values.name}
        placeholder="NAME"
      />
      <input
        className={input}
        type="email"
        name="email"
        onChange={formik.handleChange}
        value={formik.values.email}
        placeholder="EMAIL"
      />
      <input
        className={input}
        type="text"
        name="subject"
        onChange={formik.handleChange}
        value={formik.values.subject}
        placeholder="SUBJECT"
      />
      <textarea
        className={input}
        name="body"
        rows={8}
        onChange={formik.handleChange}
        value={formik.values.body}
        placeholder="MESSAGE"
      />
      <div className="text-center">
        <motion.button
          className="overflow-hidden rounded-xl text-white bg-black px-20 uppercase text-sm py-2 tracking-widest hover:bg-primary hover:scale-110 transform transition-all"
          type="submit"
          {...SlideLeft}
        >
          submit
          <Submitting isSubmitting={formik.isSubmitting} />
        </motion.button>
      </div>
    </motion.form>
  );
}
