import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../Store';
import { instagramAdapter } from './instagram.slice';

export const instagramFeedSelector = instagramAdapter.getSelectors(
  (state: RootState) => state.instagram.ig_feed,
);

// select all instagram feed
export const selectAllInstagramFeedSelector = createSelector(
  instagramFeedSelector.selectAll,
  (item) => item,
);
