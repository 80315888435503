import { MotionProps } from 'framer-motion';
import { TransitionPowerOne } from './Transitions';

export const ScaleDown: MotionProps = {
  initial: {
    opacity: 0,
    scale: 0.8,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    ...TransitionPowerOne,
  },
};
