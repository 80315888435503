import { Link, useHistory } from 'react-router-dom';
import { Menu } from './Menu';
import { SlideDown } from '../../components/FramerMotion/SlideDown';
import { motion } from 'framer-motion';
import { useState } from 'react';

export default function DesktopScreen() {
  const history = useHistory();
  const [menu, toggleMenu] = useState<string>(history.location.pathname);

  const mapMenus = Menu.map((item, idx) => (
    <li className={`${item.url === menu ? 'current-menu' : ''} nav-link`} key={idx}>
      <Link to={item.url} onClick={() => toggleMenu(item.url)}>
        {item.logo && <img className="w-36" src={item.logo} alt={item.alt} loading="lazy" />}
        {item.name}
      </Link>
    </li>
  ));

  return (
    <motion.nav className="hidden lg:flex | justify-center items-center py-5" {...SlideDown}>
      <ul className="nav-lists-container flex flex-row items-center">{mapMenus}</ul>
    </motion.nav>
  );
}
