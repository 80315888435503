import { RoutePattern } from '../../routes/RoutePattern';
import LogoGIF from '../../logo.gif';

export interface MenuTypes {
  name?: string;
  url: RoutePattern;
  logo?: string;
  alt?: string;
}

export const Menu: MenuTypes[] = [
  {
    name: 'home',
    url: RoutePattern.Home,
  },
  {
    name: 'models',
    url: RoutePattern.Models,
  },
  {
    name: 'join us',
    url: RoutePattern.JoinUs,
  },
  {
    url: RoutePattern.Home,
    logo: LogoGIF,
    alt: 'angels and angles logo',
  },
  {
    name: 'about',
    url: RoutePattern.About,
  },
  {
    name: 'contact',
    url: RoutePattern.ContactUs,
  },
  {
    name: 'shop',
    url: RoutePattern.Shop,
  },
];
