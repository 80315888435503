import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import ScrollToTop from 'react-router-scroll-top';
import { Navigation, Preloader } from './components';
import './styles/main.css';
import './App.css';

function App() {
  return (
    <Suspense fallback={<Preloader />}>
      <Router>
        <ScrollToTop>
          <Navigation />
          <Switch>
            <Route pat="*" component={AppRoutes} />
          </Switch>
        </ScrollToTop>
      </Router>
    </Suspense>
  );
}

export default App;
