import { motion } from 'framer-motion';
import { Subscribe } from '..';
import { slideUpV1 } from '../FramerMotion/SlideUp';

export default function Footer2() {
  return (
    <motion.div
      className="flex flex-col-reverse px-10 justify-between items-center py-5  z-10 bg-none h-full | md:px-24 xl:px-32 lg:flex-row"
      {...slideUpV1}
    >
      <div className="pt-0 flex flex-row items-baseline">
        <p className="text-sm mr-2">&copy; {new Date().getFullYear()}</p>
        <span className="uppercase text-sm">ANGELS AND ANGLES.</span>
      </div>
      <div className="hidden lg:flex">
        <Subscribe />
      </div>
    </motion.div>
  );
}
