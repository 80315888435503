import styled from 'styled-components';

function Image(props: HeadImageProps) {
  return <img src={props.src} alt={props.alt} {...props} />;
}

const StyledImage = styled(Image)`
  position: absolute;
  object-fit: cover;
  z-index: 20;

  @media (min-width: 1024px) {
    height: ${({ lgh }: HeadImageProps) => lgh};
    top: ${({ lgtop }: HeadImageProps) => lgtop};
  }
`;

HeadImage.defaultProps = {
  src: '',
  alt: '',
};

interface HeadImageProps {
  lgh?: string;
  lgtop?: string;
  src?: string;
  alt?: string;
}

export default function HeadImage({ lgh, lgtop, src, alt }: HeadImageProps) {
  return <StyledImage alt={alt} src={src} lgh={lgh} lgtop={lgtop} />;
}

// import styled from 'styled-components';

// function Image(props: HeadImageProps) {
//   return <img src={props.src} alt={props.alt} {...props} />;
// }

// const StyledImage = styled(Image)`
//   position: absolute;
//   object-fit: cover;
//   z-index: 20;
//   height: ${({ h }: HeadImageProps) => h};
//   top: ${({ top }: HeadImageProps) => top};

//   @media (min-width: 1024px) {
//     height: ${({ lgh }: HeadImageProps) => lgh};
//     top: ${({ lgtop }: HeadImageProps) => lgtop};
//   }
// `;

// HeadImage.defaultProps = {
//   src: '',
//   alt: '',
// };

// interface HeadImageProps {
//   lgh?: string;
//   lgtop?: string;
//   h?: string;
//   top?: string;
//   src?: string;
//   alt?: string;
// }

// export default function HeadImage({ lgh, lgtop, h, top, src, alt }: HeadImageProps) {
//   return <StyledImage alt={alt} src={src} lgh={lgh} lgtop={lgtop} h={h} top={top} />;
// }
