import Slider, { Settings } from 'react-slick';
import useVideoService from '../../app/Hooks/useVideoService';

interface YoutubeSlideshowProps {
  children: any;
  id: string;
}

export default function DefaultPlayerSlider({ id, children }: YoutubeSlideshowProps) {
  const { getModelVideos } = useVideoService();
  getModelVideos(id);

  const settings: Settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 1000,
    autoplaySpeed: 2000,
    focusOnSelect: true,
    pauseOnHover: false,
    dots: false,
    autoplay: false,
    arrows: true,
    swipe: true,
    swipeToSlide: true,
    className: 'youtube-slider default-player relative w-full',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          swipe: true,
          swipeToSlide: true,
        },
      },
    ],
  };

  return <Slider {...settings}>{children}</Slider>;
}
