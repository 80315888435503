import { ajax } from 'jquery';
import axios from 'axios';
import dayjs from 'dayjs';

export interface NewsletterFormTypes {
  IP: string;
  Email: string;
}

export default class NewsletterService {
  async subscribeUser({ Email, IP }: NewsletterFormTypes) {
    try {
      const dayCreated = dayjs('2019-01-25').format('YYYY-MM-DDTHH:mm:ssZ[Z]');

      await ajax(
        'https://script.google.com/macros/s/AKfycbxy6m0jtBUgPARNyv4lRqIuaBomqTbsxN1OEtedMeMafjmCTfu1zT10/exec',
        {
          data: {
            TimeStamp: dayCreated,
            Email: Email,
            IP: IP,
          },
        },
      );
    } catch (error) {
      console.error(error);
    }
  }

  async getUsersIp() {
    const { data } = await axios.get('https://api.ipify.org/?format=json');
    return data.ip;
  }
}
