import { MotionProps } from 'framer-motion';
import { TransitionPowerOne } from './Transitions';

export const ContactSlideLeft: MotionProps = {
  initial: {
    opacity: 0,
    x: -100,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: -100,
  },
  transition: {
    ...TransitionPowerOne,
    delay: 0.4,
  },
};

export const ContactSlideRight: MotionProps = {
  initial: {
    opacity: 0,
    x: 250,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: 250,
  },
  transition: {
    ...TransitionPowerOne,
    delay: 1.5,
  },
};
