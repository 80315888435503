import { faComment, faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface InstagramStatsProps {
  heartCount: number;
  commentCount: number;
}

export default function InstagramStats({ heartCount, commentCount }: InstagramStatsProps) {
  return (
    <div className="flex flex-row position-center">
      <div className="text-white font-helvetica font-bold flex flex-row justify-center items-center leading-0 mr-16 lg:mr-10">
        <p className="leading-0 mr-2 text-xl">
          <FontAwesomeIcon icon={faHeart} />
        </p>
        <p className="leading-0 text-md">{heartCount}</p>
      </div>
      <div className="text-white font-helvetica font-bold flex flex-row justify-center items-center leading-0">
        <p className="leading-0 mr-2 text-xl transform ">
          <FontAwesomeIcon icon={faComment} />
        </p>
        <p className="leading-0 text-md">{commentCount}</p>
      </div>
    </div>
  );
}
