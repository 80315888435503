import { Container } from '..';
import DesktopScreen from './DesktopScreen';
import SmallScreen from './SmallScreen';

export default function Navigation() {
  return (
    <Container>
      <DesktopScreen />
      <SmallScreen />
    </Container>
  );
}
