import { RoutePattern } from '../routes/RoutePattern';

export function CurrentRoute(route: string) {
  if (route === RoutePattern.Home) {
    return RoutePattern.Models;
  } else if (route === RoutePattern.Models) {
    return RoutePattern.JoinUs;
  } else if (route === RoutePattern.JoinUs) {
    return RoutePattern.About;
  } else if (route === RoutePattern.About) {
    return RoutePattern.ContactUs;
  } else if (route === RoutePattern.ContactUs) {
    return RoutePattern.Shop;
  } else if (route.includes('/models/')) {
    return RoutePattern.Models;
  } else {
    return RoutePattern.Home;
  }
}

export function ReverseRoute(route: string) {
  if (route === RoutePattern.Home) {
    return RoutePattern.Shop;
  } else if (route === RoutePattern.Models) {
    return RoutePattern.Home;
  } else if (route === RoutePattern.JoinUs) {
    return RoutePattern.Models;
  } else if (route === RoutePattern.About) {
    return RoutePattern.JoinUs;
  } else if (route === RoutePattern.ContactUs) {
    return RoutePattern.About;
  } else if (route.includes('/models/')) {
    return RoutePattern.Models;
  } else {
    return RoutePattern.ContactUs;
  }
}
