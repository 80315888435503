import { motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { Heading } from '..';
import useInstagramService from '../../app/Hooks/useInstagramService';
import { lg, sm, xl } from '../../helpers/Screensize';
import { SlideLeft } from '../FramerMotion/SlideLeft';
import { SlideRight } from '../FramerMotion/SlideRight';
import useScrolly from '../Hooks/useScrolly';
import Slider from '../Slider/Slider';
import SocialMedia from '../SocialMedia/SocialMedia';
import HeadImage from './HeadImage';

interface ModelShowcaseProps {
  objectPos?: string; // static important
  imageUrls: string[];
  mobileImageUrl: string;
  showcase?: {
    // static important
    lgh?: string;
    lgtop?: string;
    h?: string;
    top?: string;
  };
  socials: string[];
  modelName: string;
  modelInfo: string;
  instagramId: string;
  showHeadImage?: boolean;
}

export default function ModelShowcase({
  objectPos,
  imageUrls,
  mobileImageUrl,
  socials,
  modelName,
  modelInfo,
  instagramId,
  showcase,
  showHeadImage,
}: ModelShowcaseProps) {
  const { getInstagramFeed } = useInstagramService();
  const { gsapSlideToLeft, gsapSlideToRight, gsapSlideToDown } = useScrolly();
  const firstImage = useRef(null);
  const secondImage = useRef(null);
  const thirdImage = useRef(null);
  const paragraphRef = useRef(null);
  const slider = useRef(null);

  useEffect(() => {
    gsapSlideToRight(firstImage);
    gsapSlideToLeft(secondImage);
    gsapSlideToLeft(thirdImage);
    gsapSlideToDown(slider, sm && '70%');
    gsapSlideToDown(paragraphRef, sm() ? '100%' : '50%', sm() ? 0 : 1);

    getInstagramFeed(instagramId);
  }, [gsapSlideToDown, gsapSlideToLeft, gsapSlideToRight, getInstagramFeed, instagramId]);

  return (
    <div>
      {/* Model */}
      <div className="flex flex-row gap-5 pt-10 md:pt-10 lg:pt-5">
        {!lg() ? (
          <motion.div ref={firstImage} className="w-full relative opacity-20" {...SlideRight}>
            {showHeadImage && (
              <HeadImage
                lgtop={showcase?.lgtop}
                lgh={showcase?.lgh}
                src={imageUrls[3]}
                alt={modelName}
              />
            )}
            <img
              className={`h-screen lg:h-5/6 w-full object-cover z-10 ${objectPos}`}
              src={imageUrls[0]}
              alt="treysongz"
              loading="lazy"
            />
          </motion.div>
        ) : (
          <motion.div className="w-full">
            <img className="w-full object-cover" src={mobileImageUrl} alt={modelName} />
          </motion.div>
        )}
        <div ref={secondImage} className="hidden | lg:block md:w-1/2">
          <motion.img
            className="w-full relative h-5/6 object-cover z-10"
            src={imageUrls[1]}
            alt="treysongz"
            loading="lazy"
            {...SlideLeft}
          />
        </div>
        <div ref={thirdImage} className="hidden | lg:block md:w-1/2">
          <motion.img
            className="w-full h-5/6 object-cover"
            src={imageUrls[2]}
            alt="treysongz"
            loading="lazy"
            {...SlideLeft}
            transition={{ delay: 1 }}
          />
        </div>
      </div>

      {/* Information */}
      <div className="pt-10 lg:pt-0 md:mt-0">
        <Heading opacity={sm() ? 0.2 : 1} scrollStart={`${xl() ? '70%' : '20%'}`} level={3}>
          {modelName}
        </Heading>
        <SocialMedia
          opacity={sm() ? 0.2 : 1}
          scrollStart={`${xl() ? '60%' : '20%'}`}
          data={socials}
        />
        <p ref={paragraphRef} className="text-center mt-10 px-10 lg:mt-5 lg:px-32">
          {modelInfo}
        </p>
      </div>

      {/* Slider */}
      <div ref={slider} className="showcase-slider instagram-slider pl-8">
        <Slider>
          <div className="w-full h-full">
            <img
              className="w-full h-full relative object-cover"
              src={imageUrls[1]}
              alt={modelName}
              loading="lazy"
            />
          </div>
          <div className="w-full h-full">
            <img
              className="w-full h-full object-cover"
              src={imageUrls[2]}
              alt={modelName}
              loading="lazy"
            />
          </div>
          <div className="w-full h-full">
            <img
              className="w-full h-full object-cover"
              src={imageUrls[0]}
              alt={modelName}
              loading="lazy"
            />
          </div>
        </Slider>
      </div>
    </div>
  );
}
