import { GoogleMap, withScriptjs, withGoogleMap, Marker } from 'react-google-maps';

const MyMapComponent = withScriptjs(
  withGoogleMap((props: any) => (
    <GoogleMap defaultZoom={15} defaultCenter={{ lat: props.lat, lng: props.lng }}>
      {props.isMarkerShown && <Marker position={{ lat: props.lat, lng: props.lng }} />}
    </GoogleMap>
  )),
);

interface MapProps {
  style?: {
    width?: string;
    height?: string;
  };
}

export default function Map({ style }: MapProps) {
  return (
    <div className="map" style={{ ...style }}>
      <MyMapComponent
        isMarkerShown
        lat={40.6179037}
        lng={-73.9663434}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?&key=${process.env.REACT_APP_GOOGLE_MAP_API}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
}
