import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModelsTypes } from '../../api/Model/types';

export const modelsAdapter = createEntityAdapter<ModelsTypes>({
  selectId: (item) => item.id,
});

const initialState = {
  items: modelsAdapter.getInitialState(),
};

export const modelsSlice = createSlice({
  name: 'models',
  initialState,
  reducers: {
    addModelItems: (state, { payload }: PayloadAction<ModelsTypes[]>) => {
      modelsAdapter.addMany(state.items, payload);
    },
  },
});

export const { addModelItems } = modelsSlice.actions;
