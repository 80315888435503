export enum RoutePattern {
  Home = '/',
  JoinUs = '/join-us',
  ContactUs = '/contact-us',
  About = '/about',
  Models = '/models',
  SingleModel = '/models/:slug',
  Shop = '/shop',
}

export enum TestRoutes {
  Models = '/v2/models',
  SingleModel = '/v2/models/:slug',
}
