import { ajax } from 'jquery';
import axios from 'axios';
import dayjs from 'dayjs';

export interface ContactFormTypes {
  IP: string;
  Email: string;
  Name: string;
  Subject: string;
  Message: string;
}

export default class ContactService {
  async subscribeUser({ Email, Name, Subject, Message, IP }: ContactFormTypes) {
    try {
      const dayCreated = dayjs('2019-01-25').format('YYYY-MM-DDTHH:mm:ssZ[Z]');

      await ajax(
        'https://script.google.com/macros/s/AKfycbwM3rGfXtuA5fvwtJQijMxsM2Wk1OtEXECgYWu43F59ioeTBrBwzo5I/exec',
        {
          data: {
            TimeStamp: dayCreated,
            Email,
            Name,
            Subject,
            Message,
            IP,
          },
        },
      );
    } catch (error) {
      console.error(error);
    }
  }

  async getUsersIp() {
    const { data } = await axios.get('https://api.ipify.org/?format=json');
    return data.ip;
  }
}
