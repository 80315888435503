import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModelsVideosTypes } from '../../api/Videos/types';

export const videosAdapter = createEntityAdapter<ModelsVideosTypes>({
  selectId: (item) => item.id,
});

const initialState = {
  items: videosAdapter.getInitialState(),
};

export const videosSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    addVideosItems: (state, { payload }: PayloadAction<ModelsVideosTypes>) => {
      videosAdapter.addOne(state.items, payload);
    },
    removeAllVideos: (state) => {
      videosAdapter.removeAll(state.items);
    },
  },
});

export const { addVideosItems, removeAllVideos } = videosSlice.actions;
