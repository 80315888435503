import { Container, Footer, Heading, Join } from '../../components';

export default function JoinUs() {
  return (
    <>
      <Container py="10">
        <div className="flex" style={{ minHeight: '75vh' }}>
          <div className="w-full lg:m-auto lg:w-1/2">
            <Heading level={2}>join us</Heading>
            <Join mw="700" pt="16" />
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
