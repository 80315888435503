export function findChexy(ref: any, numberOfItems: number) {
  const modelItem = ref;
  let targets: any = [1];
  const totalItems = Math.floor(numberOfItems / 4);
  let index = 1;

  for (let i = 0; i <= totalItems; i++) {
    targets.push((index += 3));
  }

  const current: any = modelItem.current;
  const childrens = current.children;

  for (let i = 0; i <= targets.length; i++) {
    const elementTarget = childrens[targets[i]];
    if (!elementTarget) return;
    elementTarget.classList.add('model-target');
  }
}
