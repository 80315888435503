import gsap from 'gsap';
// import { lg } from '../../../helpers/Screensize';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { md } from '../../../helpers/Screensize';

export default function BatchReveal(ref?: any) {
  // const config = { threshold: 0.5 };

  // let observer = new IntersectionObserver(function (entries, self) {
  //   let targets = entries.map((entry) => {
  //     if (entry.isIntersecting) {
  //       self.unobserve(entry.target);
  //       return entry.target;
  //     }
  //     return false;
  //   });

  //   // Trigger();
  //   animate(targets);
  // }, config);

  // document.querySelectorAll('.model-item').forEach((box) => {
  //   observer.observe(box);
  // });

  // function animate(targets: any) {
  //   gsap.to(targets, {
  //     opacity: 1,
  //     stagger: 0.2,
  //     y: 0,
  //     overwrite: true,
  //   });
  // }

  let models = document.querySelectorAll('.model-item');

  ScrollTrigger.batch(models, {
    interval: 0.5,
    batchMax: md() ? 1 : 3,
    onEnter: (batch) => gsap.to(batch, { autoAlpha: 1, y: 0, stagger: 0.15, overwrite: true }),
    start: 'top 60%',
  });
}
