import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InstagramTypes } from '../../api/Instagram/types';

export const instagramAdapter = createEntityAdapter<InstagramTypes>({
  selectId: (item) => item.id,
});

const initialState = {
  ig_feed: instagramAdapter.getInitialState(),
};

export const instagramSlice = createSlice({
  name: 'instagram',
  initialState,
  reducers: {
    addInstagramItems: (state, { payload }: PayloadAction<InstagramTypes[]>) => {
      instagramAdapter.addMany(state.ig_feed, payload);
    },
    removeAllInstagramItems: (state) => {
      instagramAdapter.removeAll(state.ig_feed);
    },
  },
});

export const { addInstagramItems, removeAllInstagramItems } = instagramSlice.actions;
