import { useState } from 'react';

export default function InstagramIcon() {
  const [hovered, hover] = useState(false);

  return (
    <span className="snap-icon" onMouseOver={() => hover(true)} onMouseLeave={() => hover(false)}>
      <svg width="51" height="50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M40.93 32.847c-.315.736-1.65 1.277-4.078 1.654-.125.169-.229.889-.394 1.45-.099.337-.34.537-.735.537h-.018c-.568 0-1.162-.262-2.352-.262-1.606 0-2.16.366-3.406 1.247-1.322.934-2.59 1.741-4.482 1.659-1.916.141-3.513-1.024-4.412-1.66-1.254-.886-1.806-1.246-3.405-1.246-1.142 0-1.861.286-2.353.286-.488 0-.679-.298-.752-.547-.163-.557-.266-1.288-.395-1.461-1.252-.195-4.075-.687-4.147-1.947a.643.643 0 01.537-.67c4.213-.694 6.111-5.02 6.19-5.203l.014-.031c.225-.457.275-.839.15-1.136-.306-.72-1.628-.978-2.183-1.198-1.436-.567-1.636-1.219-1.551-1.665.147-.778 1.315-1.256 1.998-.936.54.253 1.02.381 1.426.381.304 0 .497-.073.603-.131-.124-2.176-.43-5.285.344-7.022 2.045-4.584 6.379-4.94 7.658-4.94l.612-.006c3.157 0 6.191 1.621 7.673 4.943.773 1.735.469 4.831.344 7.024.096.053.264.118.521.13.387-.018.836-.145 1.336-.38.369-.171.872-.148 1.24.004l.002.001c.574.205.935.618.944 1.082.011.59-.516 1.1-1.567 1.515-.128.05-.284.1-.45.153-.593.188-1.49.472-1.733 1.045-.126.297-.076.679.15 1.135l.014.032c.078.183 1.974 4.508 6.19 5.203.389.064.675.477.467.96z"
            fill={`${hovered ? 'black' : 'white'}`}
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0"
            width="51"
            height="49.143"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset />
            <feGaussianBlur stdDeviation="5" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
        </defs>
      </svg>
    </span>
  );
}
