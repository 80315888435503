import { motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { InstagramTypes } from '../../api/Instagram/types';
import { ListsSlideUpStagger, ListsVariants } from '../FramerMotion/Lists';
import useScrolly from '../Hooks/useScrolly';
import Slider2 from '../Slider/Slider2';
import InstagramFeed from './InstagramFeed';

interface InstagramShowcaseProps {
  scrollStart?: string;
  instagramFeed: InstagramTypes[];
}

export default function InstagramShowcase({ scrollStart, instagramFeed }: InstagramShowcaseProps) {
  const ref: any = useRef(null);
  const slider = useRef(null);
  const { gsapSlideToDown, gsapInstagramFeedAnimation } = useScrolly();

  useEffect(() => {
    gsapSlideToDown(slider);
    gsapInstagramFeedAnimation(scrollStart);
  }, [gsapInstagramFeedAnimation, gsapSlideToDown, scrollStart, ref]);

  if (!instagramFeed) return null;

  const MapInstaFeeds = instagramFeed.map((item, idx) => {
    return (
      <motion.div key={idx} variants={ListsSlideUpStagger} className="w-full instagram-feed">
        <InstagramFeed
          id={item.id}
          heartCount={item.heartCount}
          commentCount={item.commentCount}
          imgURL={item.imgURL}
          redirectTo={item.redirectTo}
        />
      </motion.div>
    );
  });

  return (
    <div className="relative">
      <motion.div initial="initial" animate="animate" exit="exit" variants={ListsVariants}>
        <div ref={ref} className="hidden lg:flex flex-row gap-5 py-10">
          {MapInstaFeeds}
        </div>
      </motion.div>
      <div ref={slider} className="block lg:hidden">
        <Slider2>{MapInstaFeeds}</Slider2>
      </div>
    </div>
  );
}
