import { motion } from 'framer-motion';
import { Container, Footer, Heading, Map } from '../../components';
import { ContactSlideLeft, ContactSlideRight } from '../../components/FramerMotion/ContactUs';

export default function ContactUs() {
  return (
    <>
      <Container py="10" pb="0" lgPb="10" px="2">
        <div className="flex justify-between" style={{ minHeight: '80vh' }}>
          <div className="m-auto w-full md:w-5/6">
            <Heading level={2}>contact us</Heading>
            <div className="flex flex-col items-center justify-between pt-16 lg:py-16 | lg:flex-row">
              <div className="mr-10">
                <motion.p className="uppercase" {...ContactSlideRight}>
                  271 17th street nw, <br /> suite 125 <br />
                  atlanta, ga 30363
                </motion.p>
                <motion.div className="mt-10" {...ContactSlideRight} transition={{ delay: 1 }}>
                  <span className="uppercase font-bold mr-3">phone:</span>
                  <span className="inline-block">855.300.8209</span>
                </motion.div>
                <motion.div className="mt-3" {...ContactSlideRight} transition={{ delay: 1.2 }}>
                  <span className="uppercase font-bold mr-3">email:</span>
                  <span className="inline-block">info@arpr.com</span>
                </motion.div>
              </div>
              <motion.div className="z-20 mt-10 lg:mt-0" {...ContactSlideLeft}>
                <Map />
              </motion.div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
