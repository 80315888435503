import gsap from 'gsap';

gsap.config({
  nullTargetWarn: false,
});
export default function videoAnimation() {
  const targets = document.querySelectorAll('.playlist-video');

  gsap.fromTo(
    targets,
    {
      y: -50,
      opacity: 0.2,
    },
    {
      y: 0,
      opacity: 1,
      scrollTrigger: {
        trigger: targets[0],
        start: `top 85%`,
        toggleActions: 'play restart resume reset',
        scrub: 3,
        pin: false,
      },
    },
  );
}
