import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faSnapchatGhost,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { useEffect, useRef } from 'react';
import useScrolly from '../Hooks/useScrolly';
import { sm } from '../../helpers/Screensize';
import InstagramIcon from './Customs/Instagram';
import Snapchat from './Customs/Snapchat';
import Twitter from './Customs/Twitter';
import Facebook from './Customs/Facebook';

export interface SocialMediaProps {
  data: string[];
  scrollStart?: string;
  opacity?: number;
}

export default function SocialMedia({ data, scrollStart, opacity }: SocialMediaProps) {
  const socialsRef = useRef(null);
  const { gsapSlideDownStagger } = useScrolly();

  useEffect(() => {
    gsapSlideDownStagger(socialsRef, scrollStart, opacity);
  }, [gsapSlideDownStagger, opacity, scrollStart]);

  const mapData = data.map((item, idx) => {
    return (
      <li key={idx} className={`${item.length ? 'inline-block' : 'none'} bg-none shadow-none mr-5`}>
        <a
          className="social-icon text-white text-3xl transition-all hover:text-black"
          href={item.length ? item : '!#'}
        >
          {idx === 0 && !sm() && <FontAwesomeIcon icon={faInstagram} />}
          {idx === 0 && sm() && <InstagramIcon />}

          {idx === 1 && !sm() && <FontAwesomeIcon icon={faSnapchatGhost} />}
          {idx === 1 && sm() && <Snapchat />}

          {idx === 2 && !sm() && <FontAwesomeIcon icon={faTwitter} />}
          {idx === 2 && sm() && <Twitter />}

          {idx === 3 && !sm() && <FontAwesomeIcon icon={faFacebookF} />}
          {idx === 3 && sm() && <Facebook />}
        </a>
      </li>
    );
  });

  return (
    <ul ref={socialsRef} className="socials flex flex-row items-center justify-center mt-5">
      {mapData}
    </ul>
  );
}
